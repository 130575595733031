import format from "date-fns/format";
import { decamelizeKeys } from "humps";

import { Promotion } from "nvent-web-admin/types/Promotion";

import Resource from "./Resource";

export interface CreatePromotionPayload extends Omit<Promotion, "id" | "images" | "status"> {
  image?: File | null;
  popupImage?: File | null;
}

interface UpdatePromotionPayload extends Omit<Promotion, "status" | "images"> {
  image?: File | null;
  popupImage?: File | null;
}

const createPromotionFormData = ({
  image,
  popupImage,
  ...payload
}: CreatePromotionPayload | UpdatePromotionPayload) => {
  const preparedValues = decamelizeKeys({
    ...payload,
    countryCode: payload.countryCode.toUpperCase(),
    startDate: format(new Date(payload.startDate), "yyyy-MM-dd"),
    endDate: format(new Date(payload.endDate), "yyyy-MM-dd"),
  });

  const formPayload = new FormData();

  for (const [key, value] of Object.entries(preparedValues)) {
    formPayload.append(key, String(value));
  }

  if (image !== undefined) {
    formPayload.append("offer_image_attributes[image]", image ? image : "null");
  }

  if (popupImage !== undefined) {
    formPayload.append("popup_image_attributes[image]", popupImage ? popupImage : "null");
  }

  return formPayload;
};

export default class PromotionsResource extends Resource {
  getAll(countryCode?: string) {
    return this.http
      .get<{ data: Promotion[] }>("/promotions", {
        params: decamelizeKeys({
          countryCode,
        }),
      })
      .then(({ data }) => data);
  }

  create(payload: CreatePromotionPayload) {
    return this.http.post<Promotion>("/admin/promotions", createPromotionFormData(payload));
  }

  update({ id, ...payload }: UpdatePromotionPayload) {
    return this.http.patch<Promotion>(`/admin/promotions/${id}`, createPromotionFormData(payload));
  }

  delete(id: string) {
    return this.http.delete<void>(`/admin/promotions/${id}`);
  }
}
